import React, { useState } from 'react'
import usePageLoading from 'components/utils/usePageLoading'
import useContextTheme from 'components/utils/useContextTheme'

import { Grid } from '@mui/material'; // Ensure this import is added

import AuthDialog  from "components/account/authDialog"
import Branding from "components/account/branding"
import ProductMenu  from "components/account/productMenu"


const AdminPage = ({ data, location, ...props }) => {

  // Page context and options
  const pageLoader = usePageLoading()

  // FIXME: the page shoudln't have to call this hook, but it does
  const themeContext = useContextTheme()

  return (
    <>
      <main className={`page add-event ${pageLoader}`}>
        <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={8} md={6} lg={8}>

                <Branding />

                <AuthDialog buttonLabel={'Login'} />

                <h3>Vibemap Admin</h3>
                <p>Explore products and tools below</p>

                <ProductMenu />

            </Grid>
        </Grid>
      </main>
    </>
  )
}

export default AdminPage;