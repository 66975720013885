import React, { useState } from 'react'
import { pageStore, userStore } from "state/store-zustand"

import { Link } from "gatsby"

import { Card, CardContent, Typography } from '@mui/material'

const ProductMenu = ({ ...props }) => {

    // See navItemsInitial in src/state/store-zustand.js
    const navItems = userStore(state => state.navItems)

    const linkStyle = {
        color: 'darkblue',
        textDecoration: 'none',
        display: 'block',
        marginBottom: '10px',
    }

    const panelStyle = {
        padding: '10px',
    }

    // Group by product
    const groupedItems = navItems.reduce((acc, item) => {
        if (!acc[item.product]) {
            acc[item.product] = []
        }
        acc[item.product].push(item)
        return acc
    }, {})

    return (
        <div style={panelStyle}>
            {
                Object.keys(groupedItems).map((groupKey, index) => {
                    const group = groupedItems[groupKey]

                    return (
                        <Card variant="outlined">
                            <CardContent>
                                <h3>{groupKey}</h3>
                                {group.map((item, index) => (
                                    <Link to={item.path} style={linkStyle} >
                                        {item.title || 'View'}
                                    </Link>
                                ))}
                            </CardContent>
                        </Card>
                    )
                })
            }
        </div>
    )

}

export default ProductMenu